import axios from 'axios';
import {toast} from "react-toastify";

const API_URL = 'https://ads.octagon-lab.com';

export const GetStat = async (time_interval = 'day', project, campaignid, start, end, time_zone) => {
    try {
        const response = await axios.get(`${API_URL}/stat`, {
            headers: {
                Authorization: `basic ${localStorage.getItem('oct_ads_token')}`,
                "Content-Type": "application/json",
            },
            params: {
                time_interval,
                project,
                campaignid,
                start,
                end,
                time_zone,
            }
        });

        if (response.status === 200) {
            return response.data;
        }

    } catch (error) {
        toast.error('Failed to get stats: ' + error);
    }
}

export const CheckAuth = async (login, password) => {
    try {
        const response = await axios.get(`${API_URL}/stat`, {
            headers: {
                Authorization: `basic ${btoa(`${login}:${password}`)}`,
                "Content-Type": "application/json",
            },
            params: {
                time_interval: 'day',
                project: 'cleanify',
            }
        });

        if (response.status === 200) {
            return response.data;
        }

    } catch (error) {
        toast.error('Failed to get stats: ' + error);
    }
}
