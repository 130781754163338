import * as React from 'react';
import {useContext, useEffect} from 'react';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import {FilterContext} from "../../state/filters";
import moment from "moment-timezone";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/system";


export default function Filters() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const {selectedStartDate, setSelectedStartDate, selectedEndDate, setSelectedEndDate} = useContext(FilterContext);
    const {selectedTimezone, setTimezone} = useContext(FilterContext);
    const {selectedInterval, setInterval} = useContext(FilterContext);
    const {selectedProject, setProject} = useContext(FilterContext);
    const {dateRange, setDateRange} = useContext(FilterContext);


    let timeZones = moment.tz.names();
    timeZones.sort((a, b) => {
        let offsetA = moment.tz(a).utcOffset();
        let offsetB = moment.tz(b).utcOffset();
        return offsetA - offsetB;
    });


    const handleProjectChange = (event) => {
        setProject(event.target.value);
    };

    const handleIntervalChange = (event) => {
        setInterval(event.target.value);
    };

    const handleTimezoneChange = (event, newValue) => {
        setTimezone(newValue);
    };

    const handleStartDateChange = (date) => {
        let momentDate = moment(date).tz('UTC', false).startOf('day');
        setSelectedStartDate(momentDate.toDate());
        setDateRange('');
    }

    const handleEndDateChange = (date) => {
        let momentDate = moment(date).tz('UTC', false).endOf('day');
        setSelectedEndDate(momentDate.toDate());
        setDateRange('');
    }

    const calculateDates = (range) => {
        const date = new Date();

        const start = new Date();
        const end = new Date();

        switch (range) {
            case 'lastMonth':
                start.setMonth(date.getMonth() - 1);
                break;
            case 'last7Days':
                start.setDate(date.getDate() - 7);
                break;
            case 'today':
                break;
            case 'yesterday':
                start.setDate(date.getDate() - 1);
                end.setDate(date.getDate() - 1);
                break;
            default:
                break;
        }

        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        setSelectedStartDate(start);
        setSelectedEndDate(end);
    };

    const handleDateRangeChange = (event) => {
        setDateRange(event.target.value);
        calculateDates(event.target.value);
    };

    useEffect(() => {
        if (dateRange !== "") {
            calculateDates(dateRange);
        } // eslint-disable-next-line
    }, [dateRange]);

    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{justifyContent: 'flex-start', width: isSmallScreen ? '100%' : 'auto'}}>
                <Select
                    value={selectedProject}
                    onChange={handleProjectChange}
                    displayEmpty
                    inputProps={{'aria-label': 'Without label'}}
                    sx={{
                        width: isSmallScreen ? '80%' : 'auto',
                        mr: 2,
                        mb: 2,
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        fontSize: isSmallScreen ? '0.6rem' : '1rem',
                    }}
                >
                    <MenuItem value="" disabled>
                        Project
                    </MenuItem>
                    <MenuItem value={''}>All</MenuItem>
                    <MenuItem value={'fax'}>Fax</MenuItem>
                    <MenuItem value={'fax_d'}>Fax_d</MenuItem>
                    <MenuItem value={'2phon'}>2Phon</MenuItem>
                    <MenuItem value={'2phon_d'}>2Phon_d</MenuItem>
                    <MenuItem value={'cleanify'}>Cleanify</MenuItem>
                    <MenuItem value={'invoice'}>Invoice</MenuItem>
                    {/* Add more projects as needed */}
                </Select>
                <Select
                    value={selectedInterval}
                    onChange={handleIntervalChange}
                    displayEmpty
                    inputProps={{'aria-label': 'Without label'}}
                    sx={{
                        width: isSmallScreen ? '80%' : 'auto',
                        mr: 2,
                        mb: 2,
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        fontSize: isSmallScreen ? '0.6rem' : '1rem',
                    }}
                >
                    <MenuItem value="" disabled>
                        Select Interval
                    </MenuItem>
                    <MenuItem value={'hour'}>Hour</MenuItem>
                    <MenuItem value={'day'}>Day</MenuItem>
                    <MenuItem value={'week'}>Week</MenuItem>
                </Select>

            </Box>
            <Box sx={{justifyContent: 'flex-end'}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Select
                        value={dateRange}
                        onChange={handleDateRangeChange}
                        displayEmpty
                        inputProps={{'aria-label': 'Without label'}}
                        sx={{
                            width: isSmallScreen ? '80%' : 150,
                            mr: 2,
                            mb: 2,
                            backgroundColor: 'rgba(0, 0, 0, 0.2)',
                            fontSize: isSmallScreen ? '0.6rem' : '1rem',
                        }}
                    >
                        <MenuItem value="" disabled>
                            Select Date Range
                        </MenuItem>
                        <MenuItem value={'today'}>Today</MenuItem>
                        <MenuItem value={'yesterday'}>Yesterday</MenuItem>
                        <MenuItem value={'last7Days'}>Last 7 Days</MenuItem>
                        <MenuItem value={'lastMonth'}>Last Month</MenuItem>
                    </Select>
                    <DatePicker
                        label="Since"
                        format="dd.MM.yyyy"
                        value={selectedStartDate}
                        onChange={handleStartDateChange}
                        sx={{
                            width: isSmallScreen ? '80%' : 150,
                            mr: 2,
                            mb: 2,
                            backgroundColor: 'rgba(0, 0, 0, 0.15)',
                            '& .MuiInputBase-input': { // Add this line
                                fontSize: isSmallScreen ? '0.6rem' : '1rem',
                            },
                        }}
                    />
                    <DatePicker
                        label="Until"
                        format="dd.MM.yyyy"
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        sx={{
                            width: isSmallScreen ? '80%' : 150,
                            mb: 2,
                            backgroundColor: 'rgba(0, 0, 0, 0.15)',
                            '& .MuiInputBase-input': { // Add this line
                                fontSize: isSmallScreen ? '0.6rem' : '1rem',
                            },
                        }}
                    />
                </LocalizationProvider>

            </Box>
            <Box sx={{width: isSmallScreen ? 100 : 230}}>
                <Autocomplete
                    value={selectedTimezone}
                    onChange={handleTimezoneChange}
                    options={timeZones}
                    renderInput={(params) => <TextField {...params} label="Select Timezone"/>}
                    sx={{
                        mr: 2,
                        mb: 2,
                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        '& .MuiInputBase-input': { // Add this line
                            fontSize: isSmallScreen ? '0.6rem' : '1rem',
                        },
                    }}
                />
            </Box>

        </Box>
    );
}
