import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {AppBar, Drawer, mainListItems, thirdListItems} from "./side.jsx";
import {CombinedContext} from "../../state/context.js";
import Stat from "../stat/stat.js";
import {FilterContext} from "../../state/filters.js";
import Graph from "../graph/graph";
import Filters from "../common/filters";


export default function Dashboard() {
    const isMobile = window.matchMedia('(max-width: 600px)').matches;
    const [open, setOpen] = React.useState(false);
    const [view, setView] = React.useState('Stat');
    const initialRender = React.useRef(true);
    const {refreshStat} = React.useContext(CombinedContext);
    const {selectedStartDate, selectedEndDate} = React.useContext(FilterContext);
    const {selectedInterval} = React.useContext(FilterContext);
    const {selectedProject} = React.useContext(FilterContext);
    const {selectedTimezone} = React.useContext(FilterContext);


    const toggleDrawer = () => {
        setOpen(!open);
    };
    const changeView = (newView) => { // Add this function
        setView(newView);
    };

    React.useEffect(() => {
        if (initialRender.current) {
            refreshStat(selectedInterval, selectedProject, null, selectedStartDate, selectedEndDate, selectedTimezone);
            initialRender.current = false;
        } else {
            switch (view) {
                case 'Stat':
                    refreshStat(selectedInterval, selectedProject, null, selectedStartDate, selectedEndDate, selectedTimezone);
                    break;
                case 'Graph':
                    refreshStat(selectedInterval, selectedProject, null, selectedStartDate, selectedEndDate, selectedTimezone);
                    break;
                default:
                    break;
            }
        }
    }, [view, refreshStat, selectedStartDate, selectedEndDate, selectedInterval, selectedProject, selectedTimezone]);


    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar position="absolute" open={isMobile ? false : open}>
                <Toolbar sx={{pr: '24px'}}>
                    <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer}
                                sx={{
                                    marginRight: '36px',
                                    ...(open && {display: 'none'}),
                                }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap sx={{flexGrow: 1}}>
                        {view}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box sx={{display: 'flex'}}>
                <Drawer variant="permanent" open={isMobile ? false : open}>
                    <Toolbar sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1]}}>
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </Toolbar>
                    <Divider/>
                    <List component="nav">
                        {mainListItems(changeView)}
                    </List>
                    <Box sx={{flexGrow: 1}}/>
                    <List component="nav">
                        <Divider sx={{my: 1}}/>
                        {thirdListItems()}
                    </List>
                </Drawer>
            </Box>
            <Box component="main" sx={{flexGrow: 1, height: '100vh', overflow: 'auto'}}>
                <Toolbar/>
                <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
                    <Filters/>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper sx={{p: 1, minHeight: '400px'}}>
                                {view === 'Stat' && <Stat/>}
                                {view === 'Graph' && <Graph/>}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}
