import React, {createContext, useEffect, useState} from 'react';

// Create a new context
export const FilterContext = createContext();

const savedFilters = JSON.parse(localStorage.getItem('filters'));
export const DateProvider = ({children}) => {
    const [selectedStartDate, setSelectedStartDate] = useState(() => {
        return savedFilters?.selectedStartDate ? new Date(savedFilters.selectedStartDate) : (() => {
            const date = new Date();
            date.setDate(date.getDate() - 7);
            date.setHours(0, 0, 0, 0);
            return date;
        })();
    });
    const [selectedEndDate, setSelectedEndDate] = useState(() => {
        return savedFilters?.selectedEndDate ? new Date(savedFilters.selectedEndDate) : (() => {
            const date = new Date();
            date.setHours(23, 59, 59, 999);
            return date;
        })();
    });

    const [selectedInterval, setInterval] = useState(() => {
        return savedFilters?.selectedInterval || 'day';
    });
    const [selectedProject, setProject] = useState(() => {
        return savedFilters?.selectedProject || 'fax';
    });
    const [selectedTimezone, setTimezone] = useState(() => {
        return savedFilters?.selectedTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    });

    const [dateRange, setDateRange] = useState(() => {
        return savedFilters?.dateRange || 'last7Days';
    });

    const [dateMap, setDateMap] = useState(() => {
        return dataInitializer(selectedInterval, selectedStartDate, selectedEndDate);
    });


    // Save filters to local storage whenever they change
    useEffect(() => {
        const filters = {
            selectedStartDate,
            selectedEndDate,
            selectedInterval,
            selectedProject,
            dateRange,
            selectedTimezone,
        };
        localStorage.setItem('filters', JSON.stringify(filters));
        setDateMap(dataInitializer(selectedInterval, selectedStartDate, selectedEndDate));
    }, [selectedStartDate, selectedEndDate, selectedInterval, selectedProject, selectedTimezone, dateRange]);


    return (
        <FilterContext.Provider value={{
            selectedStartDate,
            setSelectedStartDate,
            selectedEndDate,
            setSelectedEndDate,
            selectedTimezone,
            setTimezone,
            selectedInterval,
            setInterval,
            selectedProject,
            setProject,
            dateRange,
            setDateRange,
            dateMap,
            setDateMap,
        }}>
            {children}
        </FilterContext.Provider>
    );
};

const dataInitializer = (selectedInterval, selectedStartDate, selectedEndDate) => {
    let data = {};
    let interval = selectedInterval;

    let currentDate = new Date(selectedStartDate.toString());

    while (currentDate <= selectedEndDate) {
        let day = new Date(currentDate);
        if (interval === 'hour') {
            day = day.toLocaleDateString('en-GB', {hour: '2-digit', minute: '2-digit'})
        } else {
            day = day.toLocaleDateString('en-GB')
        }

        data[day] = {};

        switch (interval) {
            case 'day':
                currentDate.setDate(currentDate.getDate() + 1);
                break;
            case 'week':
                currentDate.setDate(currentDate.getDate() + 7);
                break;
            case 'hour':
                currentDate.setHours(currentDate.getHours() + 1);
                break;
            default:
                currentDate.setDate(currentDate.getDate() + 1);
        }
    }

    return data;
}
