import {toast} from "react-toastify";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import CodeIcon from "@mui/icons-material/Code";
import {CheckAuth} from "../../services/api.js";
import {useEffect} from "react";


export default function AuthView() {
    const navigate = useNavigate();

    useEffect(() => {
        const canvas = document.getElementById('gradient-wave');
        const updateCanvasSize = () => {
            canvas.height = window.innerHeight;
            canvas.width = window.innerWidth;
        };
        updateCanvasSize();
        window.addEventListener('resize', updateCanvasSize);
        return () => {
            window.removeEventListener('resize', updateCanvasSize);
        };
    }, []);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const login = data.get('login');
        const password = data.get('password');
        try {
            const response = await CheckAuth(login, password);
            if (response) {
                // set email and pass to local storage token for basic auth
                localStorage.setItem('oct_ads_token', btoa(`${login}:${password}`));
                navigate('/dashboard');
            }
        } catch (error) {
            toast.error("Failed to login");
        }


    };

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
        }}>
            <canvas id="gradient-wave" className="gradient-wave" style={{position: 'absolute', zIndex: -1}}></canvas>
            <Box sx={{
                display: 'flex',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                flexDirection: 'column',
                alignItems: 'center',
                bgcolor: 'background.paper',
                borderRadius: 4,
                p: 3,
            }}>
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <CodeIcon/>
                </Avatar>
                <Box component="form" onSubmit={handleSubmit} width={300}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="login"
                        label="login"
                        name="login"
                        autoComplete="login"
                        autoFocus
                        style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Submit
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

